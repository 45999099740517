import React from 'react';

//types
import { Todo } from '../../../Facilities/FacilitiesListView';

// helpers
import {
  addIndefiniteArticle,
  emailValidation,
  nameValidation,
  normalizePhone,
  possessiveName,
  normalizeMonthYearDate,
  validateExperience,
  validateBio,
  phoneValidation,
} from '../../../../../helpers';

//hooks
import { useHistory } from 'react-router-dom';
import useFeatureAccessCheck from '../../../../../hooks/useFeatureAccessCheck';
import useTextFieldState from '../../../../../hooks/useTextFieldState';
import useBoolFieldState from '../../../../../hooks/useBoolFieldState';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useRoleChecker from '../hooks/useRoleChecker';
import useHandleError from '../../../../../hooks/useHandleError';
import useSnackbar from '../../../../../hooks/useSnackbar';

// queries and mutations
import { useMeQuery } from '../../../../../generated/graphql';

// components
import Dialog from '@material-ui/core/Dialog';
import { ImageUploader, Button, Checkbox } from '@betterpt/better-components';
import ProviderFields from './ProviderFields';
import ProfessionalInformationHeader from './ProfessionalInformationHeader';
import RolePicker from './RolePicker';
import BinaryRadio from '../../../../Shared/BinaryRadio';
import CreateFormNav from '../../../../Shared/CreateFormNav';
import FormField from '../../../../Shared/FormField';
import FacilitySelector from '../../FacilitySelector';

// assets & styles
import PlaceholderImage from '../../../../../assets/img-unknown-user.svg';
import { CreateTeamBody, Form, ButtonStyle, EmailOptOutLabel } from './CreateEmployeeForm.style';

// types
import { CreateEmployeeInput } from '../hooks/useCreateEmployeeOperations';

interface Props {
  createEmployee(paylod: CreateEmployeeInput): Promise<string | undefined>;
}

const CreateEmployeeForm: React.FC<React.PropsWithChildren<Props>> = ({ createEmployee }) => {
  const handleError = useHandleError();
  const snackbar = useSnackbar();
  const [saving, updateSaving] = React.useState(false);
  const history = useHistory<any>();
  const todo = history.location.state?.todo;
  const initialClinics = todo === Todo.addStaff && history.location.state?.clinicId ? [history.location.state?.clinicId] : [];

  const { employeeSingularAlias, primaryColor } = useRemoteConfigAliases();
  const { isNotWhiteLabel } = useFeatureAccessCheck();

  const rolesChecker = useRoleChecker();

  const meQuery = useMeQuery();
  const shouldNewEmployeesDefaultToProvider = meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;

  const [avatar, setAvatar] = React.useState('');
  const [clinicsToAssociateEmployee, setClinicsToAssociateEmployee] = React.useState<string[]>([]);

  const firstNameState = useTextFieldState({
    validation: nameValidation,
  });
  const lastNameState = useTextFieldState({
    validation: nameValidation,
  });

  const genderState = useTextFieldState({ required: true });
  const emailState = useTextFieldState({
    required: true,
    validation: emailValidation,
  });
  const phoneState = useTextFieldState({
    normalizer: normalizePhone,
    validation: phoneValidation,
  });
  const roleState = useTextFieldState({ required: true });
  const isTherapistState = useBoolFieldState();
  const bioState = useTextFieldState({
    validation: validateBio,
  });
  const degreeOrCertificationState = useTextFieldState();
  const yearsExperienceState = useTextFieldState({
    normalizer: normalizeMonthYearDate,
    validation: validateExperience,
  });
  const [shouldSendInvite, updateShouldSendInvite] = React.useState(true);

  const submitForm = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      const employeeId = await createEmployee({
        firstNameState,
        lastNameState,
        emailState,
        phoneState,
        roleState,
        isTherapist: shouldNewEmployeesDefaultToProvider || !!isTherapistState.value,
        genderState,
        clinicsToAssociateEmployee,
        bioState,
        degreeOrCertificationState,
        yearsExperienceState,
        avatar,
        shouldSendInvite,
      });
      updateSaving(false);
      history.push({
        pathname: `/employees/${employeeId}`,
        state: {
          congratsDialogOpen: true,
          congratsDialogText: `You’ve successfully added ${addIndefiniteArticle(
            employeeSingularAlias.toLocaleLowerCase()
          )} to our platform. They have been sent an email to set up an account on the platform.`,
        },
      });
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const selectEmployees = (ids: string[]) => setClinicsToAssociateEmployee(ids);
  const toggleShouldSendInvite = () => updateShouldSendInvite((shouldSendInvite) => !shouldSendInvite);
  const employeePossessiveName = possessiveName(employeeSingularAlias.toLocaleLowerCase());
  const nameWithArticle = addIndefiniteArticle(employeeSingularAlias);
  const showProviderFields = isNotWhiteLabel && isTherapistState.value;

  return (
    <Dialog open={true} fullScreen>
      <CreateFormNav title={`${employeeSingularAlias} Account Setup`} />
      <CreateTeamBody>
        <div>
          <ImageUploader
            type="avatar"
            image={avatar || PlaceholderImage}
            onSubmit={(event) => {
              setAvatar(event.base64Img);
            }}
            style={{ marginRight: 74 }}
            helperText="Drag or click to upload a profile picture. Maximum 250x250 px."
            onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
          />
        </div>
        <Form onSubmit={submitForm}>
          <h4 style={{ marginBottom: 32 }}>{`Add ${nameWithArticle}`}</h4>
          <FormField
            fullWidth
            label="First Name"
            placeholder={`Enter ${employeePossessiveName} first name`}
            formHandler={firstNameState}
            inputProps={{
              maxLength: 49,
            }}
          />
          <FormField
            fullWidth
            label="Last Name"
            placeholder={`Enter ${employeePossessiveName} last name`}
            formHandler={lastNameState}
            inputProps={{
              maxLength: 49,
            }}
          />
          <FormField fullWidth select label="Sex Assigned at Birth" formHandler={genderState} SelectProps={{ native: true }}>
            <option disabled value="">
              Please select sex assigned at birth
            </option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </FormField>
          <FormField label="Email" fullWidth placeholder={`Enter ${employeePossessiveName} contact email`} formHandler={emailState} />
          <FormField label="Mobile Phone" fullWidth placeholder={`Enter ${employeePossessiveName} mobile phone`} formHandler={phoneState} />

          <ProfessionalInformationHeader />
          <FacilitySelector isNewEmployee initialClinics={initialClinics} onSubmit={selectEmployees} />
          <RolePicker roleState={roleState} rolesChecker={rolesChecker} />
          {!shouldNewEmployeesDefaultToProvider && (
            <BinaryRadio
              label="Is this user a care provider?"
              value={!!isTherapistState.value}
              updateValue={isTherapistState.updateValue}
            />
          )}
          <ProviderFields
            bioState={bioState}
            yearsExperienceState={yearsExperienceState}
            degreeOrCertificationState={degreeOrCertificationState}
            showProviderFields={!!showProviderFields}
          />
          <EmailOptOutLabel>
            <Checkbox checked={shouldSendInvite} onChange={toggleShouldSendInvite} />
            <p className="Body">Send {employeeSingularAlias.toLowerCase()} an invite email to join the platform</p>
          </EmailOptOutLabel>
          <p className="Form">
            Please note, you can always choose to send an email invite later from this user&apos;s {employeeSingularAlias.toLowerCase()}{' '}
            detail page.
          </p>
          <Button size="large" type="submit" fullWidth style={ButtonStyle(primaryColor)} color="transparent" loading={saving}>
            ADD {employeeSingularAlias.toUpperCase()}
          </Button>
        </Form>
      </CreateTeamBody>
    </Dialog>
  );
};

export default CreateEmployeeForm;
