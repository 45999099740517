import React from 'react';

//types
import { HealthcareVertical } from '../../../../generated/graphql';

//hooks
import { useMeQuery, useTotalInPersonAppointmentsQuery } from '../../../../generated/graphql';
import useSnackbar from '../../../../hooks/useSnackbar';
import useFeatureAccessCheck from '../../../../hooks/useFeatureAccessCheck';

//components
import { Grid } from '../../../Shared/GridCard';
import AnalyticsCSV from './components/AnalyticsCSV';
import NoAnalytics from './components/NoAnalytics';
import ExternalAnalytics from './components/ExternalAnalytics';

export type AnalyticsListType = 'requests' | 'total' | 'confirmed' | 'declined';

const AnalyticsCSVTab = () => {
  debugger;
  const meQuery = useMeQuery();
  const snackbar = useSnackbar();
  const { isNotWhiteLabel, loading: featuresLoading } = useFeatureAccessCheck();

  const usesExternalAnalytics = !!meQuery.data?.me?.company?.externalDataPlatformLink?.length;

  const inPersonApptsQuery = useTotalInPersonAppointmentsQuery({
    fetchPolicy: 'cache-and-network',
    skip: usesExternalAnalytics,
  });

  const queryError = inPersonApptsQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const loading = inPersonApptsQuery.loading || featuresLoading;
  const nothingYet = !loading && !inPersonApptsQuery.data?.inPersonAppointments?.pager?.total;

  // commenting these out for now since we are in the testing stages
  // const vertical = meQuery.data?.me?.company?.healthcareVertical;
  // const isNotPt = vertical ? vertical !== HealthcareVertical.PhysicalTherapy : false; //defaults to assume company is PT
  // const analyticsUnavailable = isNotPt || !isNotWhiteLabel;

  if (usesExternalAnalytics) {
    return <ExternalAnalytics loading={meQuery.loading} externalDataPlatformLink={meQuery.data?.me?.company?.externalDataPlatformLink} />;
  }

  return nothingYet || !isNotWhiteLabel ? (
    <NoAnalytics unavailable={!isNotWhiteLabel} />
  ) : (
    <Grid>
      <AnalyticsCSV />
    </Grid>
  );
};

export default AnalyticsCSVTab;
