import * as React from 'react';

//types
import { AppointmentMedium, CompanyAppointmentType } from '../../../../../generated/graphql';
import { CreateInPersonAppointmentForm } from '../../InPersonAppointments/CreateInPersonAppointment/hooks/useCreateInPersonAppointmentFormHandlers';

//helpers
import { alphabeticalSort } from '@betterpt/better-components';

//hooks
import { useMeQuery, useEmployeeAppointmentTypesQuery } from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import Autocomplete from '@material-ui/lab/Autocomplete';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//styles
import { AutocompleteStyleWrapper, CardSection, appointmentTypeDrawerContainer } from './AppointmentTypePicker.style';
const autoCompleteSectionStyle = { width: '100%' };
const autoCompleteStyle = { maxWidth: '521px' };
const apptTypeOptionStyle = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap' as 'wrap',
};
const apptTypeOptionNameStyle = {
  margin: '0 6px 0 0',
};
const apptTypeOptionMediumStyle = {
  fontSize: 12,
  opacity: 0.5,
  whiteSpace: 'nowrap' as 'nowrap',
};

type DisabledAutoCompleteOption = {
  id: string;
  disabled: true;
  displayName?: string;
  medium?: AppointmentMedium;
};
type Props = {
  form: CreateInPersonAppointmentForm;
  employeeId: string;
  medium?: AppointmentMedium;
};

const AppointmentTypePicker: React.FC<React.PropsWithChildren<Props>> = ({ form, employeeId, medium }) => {
  const { employeeSingularAlias } = useRemoteConfigAliases();

  const [open, setOpen] = React.useState(false);
  const [nameInput, updateNameInput] = React.useState('');

  const meQuery = useMeQuery();
  const appointmentTypesQuery = useEmployeeAppointmentTypesQuery({
    variables: {
      id: employeeId,
    },
    skip: !employeeId || isNaN(Number(employeeId)),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const employeeAppointmentTypes: CompanyAppointmentType[] =
    (appointmentTypesQuery.data?.employee?.appointmentTypes as CompanyAppointmentType[]) ?? [];

  const appointmentTypes = employeeAppointmentTypes.filter((apptType) => (medium ? apptType?.medium === medium : !!apptType));

  const loading = meQuery.loading || appointmentTypesQuery.loading;

  const sharedProps = {
    selectOnFocus: true,
    clearOnBlur: true,
    blurOnSelect: true,
    fullWidth: true,
  };

  const renderAppointmentTypeOption = (apptType: CompanyAppointmentType | DisabledAutoCompleteOption) => (
    <div style={apptTypeOptionStyle}>
      <p style={apptTypeOptionNameStyle}>{apptType?.displayName ?? '-'}</p>
      <span style={apptTypeOptionMediumStyle}>({apptType?.medium === AppointmentMedium.InClinic ? 'In-Person' : 'Telehealth'})</span>
    </div>
  );

  if (!appointmentTypes.length) return null;

  return (
    <CardSection>
      <AutocompleteStyleWrapper>
        <div style={appointmentTypeDrawerContainer}>
          <div style={autoCompleteSectionStyle}>
            <Autocomplete
              {...sharedProps}
              options={alphabeticalSort(appointmentTypes, 'displayName')}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disabled={loading || !employeeId}
              inputValue={nameInput}
              style={autoCompleteStyle}
              loading={loading}
              loadingText="Loading..."
              onInputChange={(_, newInputValue) => updateNameInput(newInputValue)}
              getOptionLabel={(option) => `${option?.displayName}`}
              getOptionSelected={(option, value) => option?.id === value?.id || option?.displayName === value?.displayName}
              onChange={(_, value) => {
                !value
                  ? form.appointmentTypeId.updateValue('')
                  : form.appointmentTypeId.updateValue(typeof value === 'string' ? value : value.id);
              }}
              data-testid="create-appointment-apptType-info"
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Select Appointment Type (Optional)"
                  placeholder={
                    loading
                      ? 'Loading...'
                      : !employeeId
                      ? `Select ${employeeSingularAlias.toLowerCase()} before selecting an appointment type`
                      : `Type to search for an appointment type`
                  }
                  data-cy="create-appointment-appointment-type"
                />
              )}
              renderOption={renderAppointmentTypeOption}
            />
          </div>
        </div>
      </AutocompleteStyleWrapper>
    </CardSection>
  );
};

export default AppointmentTypePicker;
