import { useState } from 'react';
import { NavLink } from 'react-router-dom';
// types
import { RouteType } from './types';
import { Tab, NavToolTip } from './styles';

type Props = {
  routes: RouteType[];
  isMainNavOpen: boolean;
  onLinkClick: () => void;
};

const NavList = ({ routes, isMainNavOpen, onLinkClick }: Props) => {
  const [hoverIdx, setHoverIdx] = useState<number | null>(null);

  return (
    <div>
      <ul style={{ marginBottom: 10 }}>
        {routes.map((route, idx) => {
          if (!route.path) {
            // if you don't provide a path, it will just render the item, but with no link provided.
            // You can also give it an onclick handler if you want
            return (
              <Tab
                onClick={() => {
                  onLinkClick();
                  !!route.onClick && route?.onClick();
                }}
                isOpen={isMainNavOpen}
                className="listItem"
                key={idx}
                id={route.id}
              >
                <div>{route.icon}</div>
                {isMainNavOpen && <div>{typeof route.display === 'string' ? <p>{route.display}</p> : route.display}</div>}
              </Tab>
            );
          }
          return (
            <li onClick={onLinkClick} key={route.path}>
              <NavLink to={route.path} onMouseEnter={() => setHoverIdx(idx)} onMouseLeave={() => setHoverIdx(null)}>
                {hoverIdx === idx && !isMainNavOpen && <NavToolTip>{route.display}</NavToolTip>}
                <Tab isOpen={isMainNavOpen} className="listItem">
                  <div>{route.icon}</div>
                  {isMainNavOpen && <div>{typeof route.display === 'string' ? <p>{route.display}</p> : route.display}</div>}
                </Tab>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default NavList;
