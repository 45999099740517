import * as React from 'react';

//types
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { RecurringSeries } from '../../../../../generated/graphql';
import { CreateInPersonAppointmentForm } from '../../InPersonAppointments/CreateInPersonAppointment/hooks/useCreateInPersonAppointmentFormHandlers';

//helpers
import { setMinutes, getMinutes, format, isValid } from 'date-fns';
import { Button } from '@betterpt/better-components';
import DateFnsUtils from '@date-io/date-fns';

//hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

//styles
import { bestTextColor } from '@betterpt/better-components';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';

import { Row, CardSection, subHeaderRowStyle, recurringButtonStyle } from './DateTimePickers.style';

type Props = {
  form: CreateInPersonAppointmentForm;
  openRecurringAppointmentsModal?: () => void;
};

const DateTimePickers = ({ openRecurringAppointmentsModal, form }: Props) => {
  const { primaryColor } = useRemoteConfigAliases();

  const showRecurringCta = !!openRecurringAppointmentsModal;
  const endDate = (form as any).endDate?.value?.toString();

  const handleTimeChange = (selectedTime: MaterialUiPickersDate) => {
    if (selectedTime && isValid(selectedTime)) {
      form.time.updateValue(setMinutes(selectedTime, Math.ceil(getMinutes(selectedTime) / 5) * 5));
      form.time.updateError(undefined);
    } else if (!isValid(selectedTime)) {
      form.time.updateError('Time must be in a valid format.');
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: bestTextColor(primaryColor),
      },
    },
  });

  const recurringText = () => {
    if (endDate) {
      return `RECURS THRU ${format(new Date(endDate), 'MM/dd/yyyy')}`;
    } else {
      return 'SET AS RECURRING';
    }
  };

  return (
    <CardSection>
      <Row style={subHeaderRowStyle}>
        <h2 className="H6">Appointment Info</h2>
        {showRecurringCta ? (
          <React.Fragment>
            {' '}
            <Button style={recurringButtonStyle} size="small" onClick={openRecurringAppointmentsModal} data-cy="recurring-appointment">
              {recurringText()}
            </Button>
          </React.Fragment>
        ) : null}
      </Row>
      <Row>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <KeyboardDatePicker
              disablePast
              autoOk
              style={{ width: '232px', marginRight: '60px' }}
              placeholder="Enter date here"
              label="Appointment Date"
              InputLabelProps={{ shrink: true }}
              format="MM/dd/yyyy"
              mask="__/__/____"
              id="telehealthAppointmentDate"
              value={form.date.value}
              onChange={(newDate) => {
                form.date.updateValue(newDate);
                if (form.date.error && isValid(newDate)) {
                  form.date.updateError(undefined);
                }
              }}
              error={Boolean(form.date.error)}
              helperText={form.date.error}
              onError={(error) => {
                if (error && error !== form.date.error) {
                  form.date.updateError(error);
                }
              }}
              data-cy="create-appointment-datepicker"
            />
            <KeyboardTimePicker
              autoOk
              style={{ width: '232px' }}
              label="Appointment Time"
              format="hh:mm a"
              invalidDateMessage="Invalid: must be 12hr AM/PM format"
              placeholder="Enter time here"
              id="telehealthAppointmentTime"
              minutesStep={5}
              InputLabelProps={{ shrink: true }}
              value={form.time.value}
              onChange={handleTimeChange}
              error={Boolean(form.time.error)}
              helperText={form.time.error}
              onError={(error) => {
                if (error && error !== form.time.error) {
                  form.time.updateError(error);
                }
              }}
              data-cy="create-appointment-timepicker"
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Row>
    </CardSection>
  );
};

export default DateTimePickers;
