import * as React from 'react';

//types
import { CreateInPersonAppointmentForm } from '../../InPersonAppointments/CreateInPersonAppointment/hooks/useCreateInPersonAppointmentFormHandlers';

//helpers
import { addIndefiniteArticle, normalizePhone } from '../../../../../helpers';
import { alphabeticalSort, colorValues, darkenLightColor } from '@betterpt/better-components';

//hooks
import { usePatientContactListQuery, usePatientContactDetailsQuery } from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useDebounce from '../../../../../hooks/useDebounce';

//components
import { Dialog } from '@material-ui/core';
import { Animation, Button, Radio } from '@betterpt/better-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import PatientForm from '../../../Patients/components/PatientForm';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//style
import { CardSection } from './PatientPicker.style';
import { AutocompleteStyleWrapper } from '../EmployeePicker/EmployeePicker.style';

type Props = {
  form: CreateInPersonAppointmentForm;
  usePatientSecondary: boolean;
  updateUsePatientSecondary: (useSecondary: boolean) => void;
  lockPatient?: boolean;
};

const PatientPicker: React.FC<React.PropsWithChildren<Props>> = ({ form, usePatientSecondary, updateUsePatientSecondary, lockPatient }) => {
  const { facilitiesSingularAlias, patientsSingularAlias, primaryColor } = useRemoteConfigAliases();
  const facilityId = form.facilityId.value;

  const [nameInput, updateNameInput] = React.useState('');
  const [contactName, updateContactName] = React.useState<string | null | undefined>();
  const [open, setOpen] = React.useState(false);
  const [addPatientDialogOpen, setAddPatientDialogOpen] = React.useState(false);
  // have to do this for some strange material ui autocomplete issue where it doesn't properly clear out
  // the value of field unless you remove it from the DOM completely.
  const [isRefreshing, updateIsRefreshing] = React.useState(false);

  const patientContacts = usePatientContactListQuery({
    variables: {
      input: {
        query: useDebounce(nameInput, 500),
        clinicIds: facilityId ? [facilityId] : undefined,
        limit: 10,
        offset: 0,
        isArchived: false,
      },
    },
    skip: !facilityId,
    fetchPolicy: 'cache-and-network',
  });

  const patientContactDetails = usePatientContactDetailsQuery({
    variables: {
      id: form.patientId.value,
    },
    skip: !lockPatient || !form.patientId.value || isNaN(Number(form.patientId.value)),
  });

  React.useEffect(() => {
    let timeoutFunc: number;
    updateNameInput('');
    if (!lockPatient) {
      form.patientId.updateValue('');
      updateIsRefreshing(true);
      timeoutFunc = window.setTimeout(() => {
        updateIsRefreshing(false);
      }, 500);
    }

    return () => {
      if (timeoutFunc) {
        window.clearTimeout(timeoutFunc);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const populateAppointmentFormWithNewPatient = (patientId: string, patientName: string) => {
    patientContacts.refetch();
    form.patientId.updateValue(patientId);
    updateNameInput(patientName);
    setAddPatientDialogOpen(false);
  };

  const patients = alphabeticalSort(patientContacts.data?.contacts?.result, 'lastName') ?? [];

  if (isRefreshing) {
    return <Animation type="providerAppLoader" />;
  }

  if (lockPatient) {
    if (patientContactDetails.loading) {
      return <Animation type="providerAppLoader" />;
    }

    const contact = patientContactDetails?.data?.contact;

    return (
      <CardSection>
        <h2 className="H6">{patientsSingularAlias} Info</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ThemedTextField
            label={patientsSingularAlias}
            style={{ maxWidth: '521px' }}
            disabled
            fullWidth
            value={`${patientContactDetails.data?.contact.firstName} ${patientContactDetails.data?.contact.lastName}`}
          />
          {contact?.secondaryDescription && (
            <RadioGroup name="patientContact">
              <FormControlLabel
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 5,
                }}
                value={false}
                control={
                  <Radio
                    name="primaryContact"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    checked={!usePatientSecondary}
                    onChange={(e) => {
                      updateUsePatientSecondary(!e.target.checked);
                    }}
                  />
                }
                label={
                  <p style={{ margin: 0, fontSize: 14 }}>
                    Primary: {contact?.email}
                    {contact?.email && contact?.phone ? ', ' : ''}
                    {normalizePhone(contact?.phone ?? '')}
                  </p>
                }
              />
              <FormControlLabel
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 5,
                }}
                value={true}
                control={
                  <Radio
                    name="secondaryContact"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    checked={usePatientSecondary}
                    onChange={(e) => {
                      updateUsePatientSecondary(e.target.checked);
                    }}
                  />
                }
                label={
                  <p style={{ margin: 0, fontSize: 14 }}>
                    Secondary: {contact?.secondaryDescription} - {contact?.secondaryEmail ?? ''}
                    {contact?.secondaryPhone && contact?.secondaryEmail
                      ? ', ' + normalizePhone(contact?.secondaryPhone ?? '')
                      : normalizePhone(contact?.secondaryPhone ?? '')}
                  </p>
                }
              />
            </RadioGroup>
          )}
        </div>
      </CardSection>
    );
  }

  return (
    <CardSection>
      <Dialog maxWidth="sm" fullWidth open={addPatientDialogOpen} onClose={() => setAddPatientDialogOpen(false)}>
        <div style={{ textAlign: 'center' }}>
          <h2 className="H2" style={{ padding: '10px 45px 0px' }}>
            Add {addIndefiniteArticle(patientsSingularAlias)}
          </h2>
          <PatientForm facilityId={facilityId} populateAppointmentFormWithNewPatient={populateAppointmentFormWithNewPatient} />
        </div>
      </Dialog>
      <h2 className="H6">{patientsSingularAlias} Info</h2>
      <AutocompleteStyleWrapper>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          blurOnSelect
          style={{ maxWidth: '521px' }}
          loading={patientContacts.loading}
          loadingText="Loading..."
          inputValue={nameInput}
          onInputChange={(_, newInputValue) => {
            updateNameInput(newInputValue);
            if (!newInputValue) updateUsePatientSecondary(false);
          }}
          options={patients}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disabled={!facilityId}
          data-testid="create-appointment-patient-info"
          getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
          getOptionSelected={(option, value) => option?.id === value?.id}
          onChange={(_, value) => {
            !value ? form.patientId.updateValue('') : form.patientId.updateValue(typeof value === 'string' ? value : value.id);
          }}
          noOptionsText={'No results'}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              placeholder={
                !facilityId
                  ? `Select ${addIndefiniteArticle(facilitiesSingularAlias).toLowerCase()} before selecting ${addIndefiniteArticle(
                      patientsSingularAlias
                    ).toLowerCase()}`
                  : `Type to search for ${addIndefiniteArticle(patientsSingularAlias).toLowerCase()}`
              }
              label={`Select ${patientsSingularAlias}`}
              inputProps={{
                ...params.inputProps,
              }}
              data-cy="create-appointment-patients"
            />
          )}
          renderOption={(patient) => {
            return (
              <div
                style={{
                  margin: '0 0 5px 0',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    margin: '0 0 6px 0',
                  }}
                >
                  {patient?.lastName?.toUpperCase() ?? '-'}, {patient?.firstName.toUpperCase() ?? '-'}
                </p>
                <RadioGroup name="patientContact">
                  <FormControlLabel
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginBottom: 5,
                    }}
                    value={false}
                    control={
                      <Radio
                        name="primaryContact"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        checked={form.patientId.value === patient?.id && !usePatientSecondary}
                        onChange={(e) => {
                          updateUsePatientSecondary(!e.target.checked);
                          updateContactName(e.target.checked ? null : patient?.secondaryDescription);
                        }}
                      />
                    }
                    label={
                      <p style={{ margin: 0, fontSize: 14 }}>
                        {patient?.email}
                        {patient?.email && patient?.phone ? ', ' : ''}
                        {normalizePhone(patient?.phone ?? '')}
                      </p>
                    }
                  />

                  {patient?.secondaryDescription && (
                    <FormControlLabel
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginBottom: 5,
                      }}
                      value={true}
                      control={
                        <Radio
                          name="secondaryContact"
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          checked={form.patientId.value === patient?.id && usePatientSecondary}
                          onChange={(e) => {
                            updateUsePatientSecondary(e.target.checked);
                            updateContactName(e.target.checked ? patient?.secondaryDescription : null);
                          }}
                        />
                      }
                      label={
                        <p style={{ margin: 0, fontSize: 14 }}>
                          Secondary: {patient?.secondaryDescription} - {patient?.secondaryEmail ?? ''}
                          {patient?.secondaryPhone && patient?.secondaryEmail
                            ? ', ' + normalizePhone(patient?.secondaryPhone ?? '')
                            : normalizePhone(patient?.secondaryPhone ?? '')}
                        </p>
                      }
                    />
                  )}
                </RadioGroup>
              </div>
            );
          }}
        />
        {usePatientSecondary && (
          <p
            style={{
              fontSize: 14,
              color: colorValues.charcoalgray,
              marginTop: -10,
            }}
          >
            Using secondary contact{contactName ? `: ${contactName}` : ''}
          </p>
        )}
        {!!facilityId && (
          <Button
            color="transparent"
            style={{
              color: darkenLightColor(primaryColor),
              fontWeight: 'bold',
              width: 521,
              margin: '-10px 0 10px',
            }}
            size="medium"
            onClick={() => setAddPatientDialogOpen(true)}
          >{`DON'T SEE WHO YOU'RE LOOKING FOR? ADD A NEW ${patientsSingularAlias.toUpperCase()}`}</Button>
        )}
      </AutocompleteStyleWrapper>
    </CardSection>
  );
};

export default PatientPicker;
