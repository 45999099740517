import * as React from 'react';
import { loader } from 'graphql.macro';
import { datadogRum } from '@datadog/browser-rum';

// gql
import {
  Appointment,
  VideoAppointment,
  useCreatePatientContactMutation,
  useLinkContactToPatientMutation,
  usePatientContactListLazyQuery,
  usePatientContactListQuery,
} from '../../../../../generated/graphql';

// components
import { Dialog } from '@material-ui/core';
import NewPatientContactView from './views/NewPatientContactView';
import LinkExistingContactView from './views/LinkExistingContactView';
import SearchExistingContactsView from './views/SearchExistingContactsView';
import { Animation, CloseButton } from '@betterpt/better-components';

// hooks
import useSnackbar from '../../../../../hooks/useSnackbar';
import useHandleError from '../../../../../hooks/useHandleError';

// queries
const FETCH_IN_PERSON_APPOINTMENT_DETAILS = loader('../../../../../gql/inPersonAppointments/InPersonAppointmentDetails.graphql');
const FETCH_PATIENT_DETAILS = loader('../../../../../gql/patients/PatientDetails.graphql');

type Props = {
  appointment: Appointment | VideoAppointment;
  isOpen: boolean;
  handleClose: () => void;
  telehealth?: boolean;
};

const LinkContactAndPatientDialog = ({ isOpen, handleClose, appointment, telehealth }: Props) => {
  const snackbar = useSnackbar();
  const [createNewContact] = useCreatePatientContactMutation();
  const [linkContact] = useLinkContactToPatientMutation();
  const [searchForMatches, searchForMatchesResults] = usePatientContactListLazyQuery();
  const handleError = useHandleError();

  const [saving, updateSaving] = React.useState(false);
  const [view, updateView] = React.useState<'addNew' | 'linkExisting' | 'searchExisting'>();
  const [contactMatchSearchText, updateContactMatchSearchText] = React.useState('');
  const [selectedSearchContactId, updateSelectedSearchContactId] = React.useState<string>();

  const clinicIds = appointment.clinicId || appointment.clinic?.id ? [appointment.clinicId ?? appointment.clinic?.id ?? '0'] : [];

  const contactQueryResults = usePatientContactListQuery({
    variables: {
      input: {
        query: appointment.patient?.email ?? appointment.patientEmail ?? '',
        clinicIds,
      },
    },
    skip: !appointment.patient?.email,
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (contactQueryResults.data && !contactQueryResults.loading && contactQueryResults.called) {
      if (!!contactQueryResults.data.contacts?.result?.length) {
        updateView('linkExisting');
      } else {
        updateView((oldView) => {
          if (oldView !== 'searchExisting') {
            return 'addNew';
          } else return oldView;
        });
      }
    }
    return () =>
      updateView((oldView) => {
        if (oldView !== 'searchExisting') {
          return 'addNew';
        } else return oldView;
      });
  }, [contactQueryResults]);

  const searchForContactMatches = () => {
    searchForMatches({
      variables: {
        input: {
          query: contactMatchSearchText,
          clinicIds,
        },
      },
    });
  };

  const createAndLinkNewContact = async () => {
    updateSaving(true);
    try {
      const { data: newContactData } = await createNewContact({
        variables: {
          input: {
            clinicId: appointment.clinicId ?? appointment.clinic?.id ?? '0',
            firstName: appointment.patient?.firstName ?? appointment?.patientFirstName ?? 'Not Provided',
            lastName: appointment.patient?.lastName ?? appointment?.patientLastName ?? 'Not Provided',
            email: appointment.patient?.email ?? appointment.patientEmail ?? '',
            phone: appointment.patient?.phone?.split('-').join(''),
            dateOfBirth: appointment.patient?.birthday ?? '',
          },
        },
      });

      if (newContactData?.createContact.id && appointment.patient?.id) {
        await linkContact({
          variables: {
            input: {
              contactId: newContactData.createContact.id,
              patientId: appointment.patient?.id,
            },
          },
          refetchQueries: [
            {
              query: FETCH_IN_PERSON_APPOINTMENT_DETAILS,
              variables: { id: appointment.id },
            },
            {
              query: FETCH_PATIENT_DETAILS,
              variables: {
                id: appointment.patient?.id,
                clinicId: appointment.clinicId,
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        datadogRum.addUserAction('create_new_contact_from_patient', {
          contactId: newContactData.createContact.id,
          patientId: appointment.patient?.id,
        });
      }

      snackbar?.setSuccessMessage('New patient profile successfully created and linked to this info!');
    } catch (e) {
      handleError(e);
    }
    updateSaving(false);
    handleClose();
  };

  const linkExistingContact = async (contactId?: string) => {
    updateSaving(true);
    try {
      if (contactId && appointment.patient?.id) {
        await linkContact({
          variables: {
            input: {
              contactId: contactId,
              patientId: appointment.patient?.id,
            },
          },
          refetchQueries: [
            {
              query: FETCH_IN_PERSON_APPOINTMENT_DETAILS,
              variables: { id: appointment.id },
            },
            {
              query: FETCH_PATIENT_DETAILS,
              variables: {
                id: appointment.patient?.id,
                clinicId: appointment.clinicId,
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      }

      datadogRum.addUserAction('link_patient_to_existing_contact', {
        contactId,
        patientId: appointment.patient?.id,
      });

      snackbar?.setSuccessMessage('Patient profile successfully linked!');
    } catch (e) {
      handleError(e);
    }

    updateSaving(false);
    handleClose();
  };

  const renderContent = () => {
    if (contactQueryResults.loading) {
      return <Animation type="providerAppLoader" />;
    }
    switch (view) {
      case 'addNew':
        return (
          <NewPatientContactView
            patient={appointment.patient}
            handleCreateAndLinkNewContact={createAndLinkNewContact}
            handleSearchContactsClick={() => updateView('searchExisting')}
            saving={saving}
          />
        );
      case 'linkExisting':
        return (
          <LinkExistingContactView
            contact={contactQueryResults.data?.contacts.result?.[0]}
            patient={appointment.patient}
            handleLinkExistingContact={() => linkExistingContact(contactQueryResults.data?.contacts.result?.[0]?.id)}
            handleCreateAndLinkNewContact={createAndLinkNewContact}
            saving={saving}
          />
        );
      case 'searchExisting':
        return (
          <SearchExistingContactsView
            saving={saving}
            potentialMatches={searchForMatchesResults.data?.contacts.result ?? []}
            updateContactMatchSearchText={updateContactMatchSearchText}
            searchForContactMatches={searchForContactMatches}
            loadingMatches={searchForMatchesResults.loading}
            selectedSearchContactId={selectedSearchContactId}
            updateSelectedSearchContactId={updateSelectedSearchContactId}
            handleLinkExistingContact={() => linkExistingContact(selectedSearchContactId)}
            searchHasBeenCalled={searchForMatchesResults.called}
          />
        );

      default:
        break;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      {renderContent()}
      <CloseButton onClose={handleClose} />
    </Dialog>
  );
};

export default LinkContactAndPatientDialog;
