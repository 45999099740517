import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

// components
import { Button } from '@betterpt/better-components';

// config
import { firebaseAnalytics } from '../../../config/firebase';

// hooks
import { useParams } from 'react-router-dom';
import useAuthentication from '../../../hooks/useAuthentication';

// style
import {
  Container,
  FormSection,
  DemoSection,
  demoButtonStyle,
  ImageLogo,
  // NewUpdatesCopy,
  PoweredByCopy,
  InnerFormSection,
  WatchTutorialCopy,
} from './AuthWrapper.style';

import { determineVariant } from './loginVariantConfig';

// helpers
import updateFavicon from '../../../helpers/updateFavicon';

const AuthWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { variant } = useParams<{ variant: string }>();

  const auth = useAuthentication();
  const pageConfig = determineVariant(variant);

  const handleRequestDemoClick = () => {
    firebaseAnalytics.logEvent('click_request_demo', {
      partner: variant,
    });
    window.open(pageConfig.demoButtonLink ?? 'https://calendly.com/betterhealthcare/betteraccess-demo');
  };

  React.useEffect(() => {
    const determineFaviconType = () => {
      if (window.location.host.includes('acorn')) {
        return 'acorn';
      } else if (window.location.host.includes('somatus')) {
        return 'somatus';
      } else {
        return 'betteraccess';
      }
    };
    updateFavicon({ faviconType: determineFaviconType() });
  }, []);

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!auth?.error}
        onClose={auth?.clearErrorMessage}
        message={auth?.error}
      />
      <FormSection>
        <InnerFormSection>
          <ImageLogo src={pageConfig.companyLogo} alt="logo" wideLogo />
          {children}
          {/*
           TODO: Hide the beamer app for now. May be bringing it back
           <NewUpdatesCopy id='notificationButton' role='button' tabIndex={0}>
            <p>Learn about recent updates to our platform</p>
          </NewUpdatesCopy> */}
        </InnerFormSection>
        {pageConfig.showPoweredByCopy && (
          <PoweredByCopy className="H4">
            <h4>POWERED BY BETTERACCESS</h4>
          </PoweredByCopy>
        )}
      </FormSection>
      <DemoSection>
        <div className="copy-and-link">
          <h2 className="H3">{pageConfig.header}</h2>
          <p className="Paragraph" style={{ maxWidth: '440px' }}>
            {pageConfig.subHeader}
          </p>
          {pageConfig.showButton && (
            <Button style={demoButtonStyle} onClick={handleRequestDemoClick}>
              {pageConfig.demoButtonCopy}
            </Button>
          )}
          {pageConfig.showWatchTutorialLink && (
            <WatchTutorialCopy className="h3">
              <a
                href="https://www.youtube.com/playlist?list=PLErUCW5OKxrgvuxRmrpEC6MC-4VwTYJQK"
                className="button-or-link"
                target="_blank"
                rel="noreferrer"
              >
                WATCH THE TUTORIALS
              </a>
            </WatchTutorialCopy>
          )}
        </div>
        <img src={pageConfig.demoImage} alt="" />
      </DemoSection>
    </Container>
  );
};

export default AuthWrapper;
