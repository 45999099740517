import React from 'react';
import axios from 'axios';
import { loader } from 'graphql.macro';

//helpers
import states from '../../../../../constants/states';
import {
  emailValidation,
  hasValueChangedInObject,
  normalizePhone,
  normalizeZipcode,
  parseAddressFromString,
  Roles,
  phoneValidation,
  urlValidation,
  nameValidation,
} from '../../../../../helpers';

//hooks
import { useMeQuery, useUpdateCompanyMutation, useUpdateCompanyLocationMutation } from '../../../../../generated/graphql';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../hooks/useHandleError';

//components
import { Header, Button, Animation, ImageUploader, InfoBox } from '@betterpt/better-components';
import FormField from '../../../../Shared/FormField';
import PossibleAddressesDialog from '../../../../Shared/PossibleAddressesDialog';
import Can from '../../../../Can';

//assets
import PlaceholderImage from '../../../../../assets/img-unknown-user.svg';

//styles
import { GridCard } from '../../../../Shared/GridCard';
import { FormBody, buttonStyle } from '../../Company.style';
import { CardTitle, headerStyle } from '../CompanyInfoTab.style';
import { ProfileImage } from '../../../Employees/EmployeeDetail/styles/EmployeeDetail.style';

//types
const QUERY_ME = loader('../../../../../gql/employee/Me.graphql');

const Contact = () => {
  //hooks
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const meQuery = useMeQuery();
  const auth = useAuthentication();
  const { primaryColor } = useRemoteConfigAliases();

  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const [addresses, setAddresses] = React.useState();
  const address = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const city = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const state = useTextFieldState({ required: true });
  const zipcode = useTextFieldState({
    required: true,
    normalizer: normalizeZipcode,
  });
  const phoneNumber = useTextFieldState({
    required: true,
    normalizer: normalizePhone,
    validation: phoneValidation,
  });
  const contactEmail = useTextFieldState({
    required: true,
    validation: emailValidation,
  });
  const companyWebsite = useTextFieldState({
    required: false,
    validation: urlValidation,
  });

  const [updateCompany, updateCompanyOptions] = useUpdateCompanyMutation({
    refetchQueries: [{ query: QUERY_ME }],
  });

  const [updateCompanyLocation, updateCompanyLocationOptions] = useUpdateCompanyLocationMutation({
    refetchQueries: [{ query: QUERY_ME }],
    onError: (e) => {
      throw new Error(JSON.stringify(e.graphQLErrors[0]?.extensions?.exception?.['potentialLocations'] ?? e));
    },
  });

  React.useEffect(() => {
    if (meQuery.data) {
      const company = meQuery.data?.me?.company;
      phoneNumber.updateValue(company?.phone ?? '');
      contactEmail.updateValue(company?.email ?? '');
      companyWebsite.updateValue(company?.website ?? '');
      address.updateValue(company?.location?.address?.trim() ?? '');
      state.updateValue(company?.location?.state ?? '');
      city.updateValue(company?.location?.city?.trim() ?? '');
      zipcode.updateValue(company?.location?.zipcode ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  // functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const location = meQuery.data?.me?.company?.location;
    const companyId = meQuery.data?.me?.company?.id;
    if (!companyId || !location) return;
    try {
      const companyContact = {
        phone: phoneNumber.value,
        email: contactEmail.value,
        website: companyWebsite.value,
      };
      hasValueChangedInObject(meQuery.data?.me?.company, companyContact) &&
        (await updateCompany({
          variables: {
            input: {
              companyId,
              ...companyContact,
            },
          },
        }));
      const newLocation = {
        address: address.value,
        city: city.value,
        state: state.value,
        zipcode: zipcode.value,
      };

      hasValueChangedInObject(location, newLocation) &&
        (await updateCompanyLocation({
          variables: {
            input: {
              companyId,
              ...newLocation,
            },
          },
        }));
      snackbar?.setSuccessMessage('Company Info saved');
      setEditModeActive(false);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message?.includes('formattedAddress')) {
          setAddresses(JSON.parse(e.message));
        } else {
          handleError(e);
        }
      }
    }
  };

  const handleAddressesDone = (formattedAddress?: string) => {
    if (formattedAddress) {
      const { zipcode: newZipcode, address: newAddress, city: newCity, state: newState } = parseAddressFromString(formattedAddress);

      zipcode.updateValue(newZipcode);
      address.updateValue(newAddress);
      city.updateValue(newCity);
      state.updateValue(newState);
    }
    setAddresses(undefined);
  };

  // values
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';
  const disableButton =
    !address.valid || !state.valid || !city.valid || !zipcode.valid || !phoneNumber.valid || !contactEmail.valid || !companyWebsite.valid;

  const saving = !!updateCompanyOptions.loading;

  const fetching = meQuery.loading;
  return (
    <GridCard>
      <PossibleAddressesDialog addresses={addresses} onClose={handleAddressesDone} />
      {fetching ? (
        <Animation type="providerAppLoader" />
      ) : (
        <form onSubmit={handleSubmit}>
          <Header underline style={headerStyle}>
            <CardTitle>Contact</CardTitle>
            <Can
              role={Roles[meQuery.data?.me?.role || 'initial']}
              perform="company:edit"
              yes={() => (
                <>
                  {isEditModeActive ? (
                    <Button
                      disabled={disableButton || saving}
                      size="small"
                      color="transparent"
                      type="submit"
                      style={buttonStyle(primaryColor, true)}
                      loading={saving}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button size="small" color="transparent" style={buttonStyle(primaryColor)} onClick={() => setEditModeActive(true)}>
                      EDIT
                    </Button>
                  )}
                </>
              )}
            />
          </Header>
          <FormBody>
            <FormField
              disabled={!isEditModeActive}
              fullWidth
              label="Company HQ Address"
              placeholder="Enter the address for your company headquarters"
              formHandler={address}
              inputProps={{
                maxLength: 255,
              }}
            />
            <FormField
              disabled={!isEditModeActive}
              fullWidth
              label="City"
              placeholder="Enter your company HQ's city name"
              formHandler={city}
              inputProps={{
                maxLength: 255,
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FormField
                disabled={!isEditModeActive}
                select
                label="State"
                style={{ width: '47%' }}
                formHandler={state}
                placeholder="Select a state"
                SelectProps={{
                  native: true,
                }}
              >
                <option>Select a state</option>
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.text}
                  </option>
                ))}
              </FormField>
              <FormField
                disabled={!isEditModeActive}
                label="Zipcode"
                placeholder="Enter zipcode"
                style={{ width: '47%' }}
                formHandler={zipcode}
              />
            </div>
            <FormField
              disabled={!isEditModeActive}
              label="Phone Number"
              fullWidth
              placeholder="Enter your company HQ's contact phone number"
              formHandler={phoneNumber}
            />
            <FormField
              disabled={!isEditModeActive}
              label="Company Email"
              fullWidth
              placeholder="Enter your company HQ's contact email"
              formHandler={contactEmail}
              InputProps={{
                endAdornment: (
                  <InfoBox
                    openWith="hover"
                    width={190}
                    height={130}
                    iconHeight={24}
                    iconWidth={24}
                    position={isNotWhiteLabel ? 'right' : 'left'}
                    padding={0}
                  >
                    <h4>COMPANY HQ EMAIL</h4>
                    <p style={{ fontSize: 14 }}>
                      This is a company contact email that users will be able to refer to if they have any questions.
                    </p>
                  </InfoBox>
                ),
              }}
            />
            <FormField
              disabled={!isEditModeActive}
              fullWidth
              label="Company Website"
              placeholder="Enter the URL of your company website (Optional)"
              formHandler={companyWebsite}
              inputProps={{
                maxLength: 49,
              }}
            />
          </FormBody>
        </form>
      )}
    </GridCard>
  );
};

export default Contact;
